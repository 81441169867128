const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.newmont.forwoodsafety.com',
    APP_CLIENT_ID: '361aaaetet60jgs4kaon2hoenf',
    USER_TOKEN_URL: 'https://jggak5vede.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://gfb1of6nui.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.staging.newmont.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.staging.newmont.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.staging.newmont.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.newmont.forwoodsafety.com',
    WEBSOCKET: 'wss://qie4vjq8uj.execute-api.us-west-2.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;